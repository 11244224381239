/**
 * 数据表相关常量
 *
 *  2021-04-22  wuzhipeng
 */

module.exports = {
  /* 物质主表*/
  DKB_SUBSTANCE: 'dkb_substance',
  /* 物质代码*/
  DKB_SUBSTANCE_CODE:'dkb_substance_code',
  /* 物质描述*/
  DKB_SUBSTANCE_DESC:'dkb_substance_desc',
  /* 物质名称*/
  DKB_SUBSTANCE_NAME:'dkb_substance_name',
  /* 物质属性*/
  DKB_SUBSTANCE_PROPS:'dkb_substance_props',
  /* 物质结构*/
  DKB_SUBSTANCE_STRUCTURE:'dkb_substance_structure',
  /* 药物主表*/
  DKB_DRUG: 'dkb_drug',
  /* 药物别名*/
  DKB_DRUG_ALIAS: 'dkb_drug_alias',
  /* 药物组*/
  DKB_DRUG_GROUP: 'dkb_drug_group',
  /* 药物成分*/
  DKB_DRUG_INGREDIENT: 'dkb_drug_ingredient',
  /* 制剂*/
  DKB_PP:'dkb_pp',
  /* 制剂规格*/
  DKB_PP_SPEC:'dkb_pp_spec',
  /* 药品*/
  DKB_DP: 'dkb_dp',
  /*包装药品*/
  DKB_PD_PROD: 'dkb_pd_prod',

}
